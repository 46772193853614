<!-- //
文章作品编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <div class="layout-result" style="overflow: auto;">
      <a-form-model ref="refForm" :model="form" :rules="rules" v-bind="formLayout" :colon="false"
                    class="ak-query-filter1">
        <!--        <a-form-model-item prop="title">-->
        <!--          <span slot="label" class="form-label">头像</span>-->
        <!--          <a-avatar size="large" shape="square" :src="ossUrl(form.logoUrl)" style="width: 86px;height: 86px"/>-->
        <!--        </a-form-model-item>-->

        <a-form-model-item prop="employeesName">
          <span slot="label" class="form-label">员工姓名</span>
          <a-input v-model="form.employeesName" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="sex">
          <span slot="label" class="form-label">性别</span>
          <a-radio-group v-model="form.sex" style="width: 250px;">
            <a-radio v-for="(item, index) in dict.type.GENDER" :value="item.value" :key="index">
              {{item.label}}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="account">
          <span slot="label" class="form-label">运营平台登录账号</span>
          <a-input v-model="form.account" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="password">
          <span slot="label" class="form-label">登录密码</span>
          <a-input v-model="form.password" style="width: 250px;"
                   placeholder="请输入"
                   type="password"
                   :readOnly="readonly"
                   @blur="readonly = true"
                   @focus.native="readonly = false"
                   @click.native="readonly = false"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item prop="phone">
          <span slot="label" class="form-label">联系方式</span>
          <a-input v-model="form.phone" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="email">
          <span slot="label" class="form-label">邮箱</span>
          <a-input v-model="form.email" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="roleId">
          <span slot="label" class="form-label">员工角色</span>
          <a-select v-model="form.roleId" style="width: 250px;" placeholder="请选择" mode="multiple" >
            <a-select-option v-for="(item,index) in roleList" :value="item.roleId" :key="index">
              {{item.roleName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-row :span="24" class="button-item">
      <a-button type="primary" class="button" @click="handleSave">确认</a-button>
      <a-button class="button2" style="margin-left: 20px" @click="handleCancel">取消</a-button>
    </a-row>
  </div>
</template>

<script>

  import * as EmployeeApi from '@/api/set/employee.api'
  import * as RoleApi from '@/api/set/role.api'
  import * as Encryptor from '@/utils/encryptor'

  export default {
    name: 'EmployeeEdit',
    components: {},
    dictTypes: [
      {
        dictType: 'GENDER',
        dataType: Number
      },
      {
        dictType: 'EmployeeStatus',
        dataType: Number
      },
    ],
    data() {
      return {
        readonly: true,
        buttonList: [
          {
            label: '保存',
            type: "primary"
          },
          {
            label: '取消',
            type: ""
          }
        ],
        roleList: [],
        formLayout: {
          labelCol: {span: 8},
          wrapperCol: {span: 14},
        },
        form: {
          id: null,
          employeesName: null,
          sex: 1,
          phone: null,
          roleId: null,
          account: null,
          password: null,
          email: null,
        },
        rules: {
          employeesName: [{
            required: true,
            message: '员工姓名不能为空',
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }],
          phone: [{
            required: true,
            message: '联系方式不能为空',
            trigger: 'change'
          }],
          roleId: [{
            required: true,
            message: '员工角色不能为空',
            trigger: 'change'
          }],
          account: [{
            required: true,
            message: '运营平台登录账号不能为空',
            trigger: 'change'
          }],
          password: [{
            required: true,
            message: '登录密码不能为空',
            trigger: 'change'
          }],
          email: [{
            required: true,
            message: '邮箱不能为空',
            trigger: 'change'
          }],
        },
      };
    },
    props: {
      mode: String,// mode=[1(新增）|2（编辑）|3（详情）]
      data: Object,
    },
    computed: {
      isEditMode() {
        return this.mode == '2'
      },
    },
    created() {
      this.showIcon = !!this.mode
      this.form = this.data || {}
      this.getInit()

      if (this.isEditMode) {
        this.getDetail()
      }
    },
    methods: {
      getInit() {
        RoleApi.selectRoleList().then(data => {
          this.roleList = data?.list
        })
      },

      getDetail() {
        EmployeeApi.get(this.form.id).then(data => {
          // console.log("================>", data)
          this.form = data || {}
        })
      },

      handleSave() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            this.form.password = Encryptor.encrypt(this.form.password)
            EmployeeApi.save(this.form).then(data => {
              if(this.mode == '1'){
              this.$modal.msg("新增员工成功")

              }else{
              this.$modal.msg("修改员工成功")

              }
              this.$emit('handleOk')
            })
          }
        })
      },

      handleCancel() {
        this.$emit('handleCancel')
      },
    }
  }
</script>
<style lang="less" scoped>
  .ant-form-item-label {
    width: 200px;
  }

  .form-label {
    font-size: 14px;
    font-weight: 400;
  }

  .button-item {
    width: 100%;
    /*height: 80px;*/
    padding: 20px;
    /*position: absolute;*/
    /*bottom: 40px;*/
    text-align: center;

    .button {
      font-size: 16px;
      width: 250px;
      height: 40px;
      line-height: 40px;
    }

    .button2 {
      font-size: 16px;
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
  }
</style>
